
import Vue from "vue"
import type { Person } from "@/types/api/person"

export default Vue.extend({
  props: {
    speaker: { type: Object as VuePropType<Person>, required: true },
  },

  computed: {
    photo (): string {
      const { speaker } = this
      return speaker.frontPagePhoto?.url || speaker.photo?.url || ""
    },

    position (): string {
      const { speaker } = this
      return ["position", "company"]
        .map(k => this.$fmt.localize(speaker[k as keyof Person]))
        .filter(Boolean)
        .join(", ")
    },
  },
})
