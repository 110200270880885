// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kqPGCSuI{padding:max(2.11rem,min(3.13vw,3.75rem)) 0}.A8yWOh-P{margin-bottom:max(2.5rem,min(4.17vw,5rem))}.A8yWOh-P>*+*{margin-left:max(.84rem,min(1.25vw,1.5rem))}.rsiQ2sRl{font-size:max(1.5rem,min(3.13vw,3.75rem));font-weight:700;letter-spacing:.03em;line-height:1}.Yi\\+FcCj1>*+*{margin-left:max(.84rem,min(1.25vw,1.5rem))}.fgbWivdV{background:none;color:inherit;transition:opacity .2s ease-in-out}.txBdtRtO{transform:rotateY(180deg)}.wuhMTiF0{opacity:.5;pointer-events:none}.fgbWivdV svg{height:max(1rem,min(.94vw,1.13rem));width:max(1rem,min(.94vw,1.13rem))}.pskWa-Pq{margin-bottom:max(2.81rem,min(4.17vw,5rem))}.lqvFc883{word-wrap:break-word;height:100%;padding:max(.56rem,min(.83vw,1rem)) 0}.I0sDYdGm{line-height:1.6;margin-bottom:max(1.2rem,min(1.77vw,2.13rem));opacity:.5}.I0sDYdGm,.ZlXRzwLw{font-size:max(1rem,min(.94vw,1.13rem))}.ZlXRzwLw{color:#b1ec52;font-weight:600;line-height:1.38;margin-bottom:max(.56rem,min(.83vw,1rem))}.pKXwKc7f{font-size:max(1rem,min(1.15vw,1.38rem));font-weight:600;line-height:1.4;margin-bottom:max(1.2rem,min(1.77vw,2.13rem));min-height:max(2.5rem,min(6.25vw,7.5rem))}.XNihcn3t{height:max(10.2rem,min(15.1vw,18.13rem));-o-object-fit:cover;object-fit:cover}.SOHRRGjn{font-size:.81rem}.Jzwu6A\\+h{color:#fff;font-size:max(1.13rem,min(1.46vw,1.75rem));font-style:normal;font-weight:600;margin-bottom:max(2.5rem,min(4.17vw,5rem))}.uN3SwC1s{height:290px;overflow:hidden;padding:30px 0 0 40px;position:relative;width:100%}.UQQvmFKV{background:#b1ec52}.VPIRmsoD{background:#fff}.WjrsT2vb{-o-object-fit:cover;object-fit:cover;position:absolute;right:0;top:60px}.ZWnfiLK3{color:#1a153f;font-size:max(1.25rem,min(1.46vw,1.75rem));font-weight:600}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "kqPGCSuI",
	"head": "A8yWOh-P",
	"heading": "rsiQ2sRl",
	"nav": "Yi+FcCj1",
	"navBtn": "fgbWivdV",
	"navBtnNext": "txBdtRtO",
	"navBtnDisabled": "wuhMTiF0",
	"swiper": "pskWa-Pq",
	"track": "lqvFc883",
	"trackDate": "I0sDYdGm",
	"trackGroup": "ZlXRzwLw",
	"trackTitle": "pKXwKc7f",
	"trackImg": "XNihcn3t",
	"btn": "SOHRRGjn",
	"trackDate": "Jzwu6A+h",
	"card": "uN3SwC1s",
	"cardGreen": "UQQvmFKV",
	"cardWhite": "VPIRmsoD",
	"trackImg": "WjrsT2vb",
	"trackTitle": "ZWnfiLK3"
};
module.exports = ___CSS_LOADER_EXPORT___;
