// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".P\\+Q-C\\+e8{background-blend-mode:hard-light,normal;background-color:#1a153e;background-image:url(/img/index/about-bg.png);background-position:top;background-repeat:no-repeat;background-size:cover;margin-bottom:max(.98rem,min(1.46vw,1.75rem));min-height:max(18.75rem,min(41.67vw,50rem));padding-bottom:30px;padding-top:max(1.88rem,min(13.54vw,16.25rem))}.gWB8MJBE{display:flex;gap:32px;justify-content:space-between}@media(max-width:991.98px){.gWB8MJBE{flex-direction:column}}.TWJ-7IYx{font-size:max(1.38rem,min(3.13vw,3.75rem));font-weight:700;letter-spacing:.03em;line-height:1}.TWJ-7IYx span{color:#b1ec52}.jRtV6VVE{font-size:max(1.13rem,min(1.46vw,1.75rem));line-height:1.6;max-width:40ch}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "P+Q-C+e8",
	"textContainer": "gWB8MJBE",
	"heading": "TWJ-7IYx",
	"text": "jRtV6VVE"
};
module.exports = ___CSS_LOADER_EXPORT___;
