// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._96IkXo70{padding:max(2.11rem,min(3.13vw,3.75rem)) 0}.PTkGCgC\\+{margin-bottom:max(2.5rem,min(4.17vw,5rem))}.PTkGCgC\\+>*+*{margin-left:max(.84rem,min(1.25vw,1.5rem))}.aHL8oR\\+w{font-size:max(1.5rem,min(3.13vw,3.75rem));font-weight:700;letter-spacing:.03em;line-height:1}.NN0bBzIs>*+*{margin-left:max(.84rem,min(1.25vw,1.5rem))}.H0uaM3gP{background:none;color:inherit;transition:opacity .2s ease-in-out}._4wCg7ZdG{transform:rotateY(180deg)}.PkcMj1sw{opacity:.5;pointer-events:none}.H0uaM3gP svg{height:max(1rem,min(.94vw,1.13rem));width:max(1rem,min(.94vw,1.13rem))}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_96IkXo70",
	"head": "PTkGCgC+",
	"heading": "aHL8oR+w",
	"nav": "NN0bBzIs",
	"navBtn": "H0uaM3gP",
	"navBtnNext": "_4wCg7ZdG",
	"navBtnDisabled": "PkcMj1sw"
};
module.exports = ___CSS_LOADER_EXPORT___;
