// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OJNtBrV8{margin-bottom:max(2rem,min(4.95vw,5.94rem))}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hero": "OJNtBrV8"
};
module.exports = ___CSS_LOADER_EXPORT___;
