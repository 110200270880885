// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EoD9Abh1{padding-top:max(0rem,min(4.27vw,5.13rem))}.YJ5aI4RT{display:flex;justify-content:space-between}@media(max-width:991.98px){.YJ5aI4RT{flex-direction:column}}.apml2rTK{font-size:max(1rem,min(1.46vw,1.75rem));font-weight:600;line-height:1.2}.U4CP8Yg6{height:max(7.5rem,min(19.79vw,23.75rem))}.MCY0dT9h,.U4CP8Yg6{position:relative}.MCY0dT9h{display:flex;justify-content:center;padding-top:3%;z-index:1}.MCY0dT9h h1{font-size:max(2rem,min(5.21vw,6.25rem));font-weight:700;line-height:1}@media(max-width:991.98px){.MCY0dT9h{flex-direction:column}}.KYMqZJlM{color:#b1ec52}.H2UyfiuJ{left:0;position:absolute;top:0}@media(max-width:991.98px){.H2UyfiuJ{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "EoD9Abh1",
	"head": "YJ5aI4RT",
	"date": "apml2rTK",
	"start": "U4CP8Yg6",
	"heading": "MCY0dT9h",
	"active": "KYMqZJlM",
	"bg": "H2UyfiuJ"
};
module.exports = ___CSS_LOADER_EXPORT___;
