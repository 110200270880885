
import breakpoints from "@/assets/style/resources/breakpoints/module.scss"

export default {
  data () {
    return {
      speakers: [],
      swiperOptions: {
        slidesPerView: 1.2,
        spaceBetween: 20,
        breakpoints: {
          [breakpoints.sm]: { slidesPerView: 3, spaceBetween: 40 },
          [breakpoints.lg]: { slidesPerView: 4, spaceBetween: 40 },
        },
        navigation: {
          prevEl: "#index-speakers-nav-prev",
          nextEl: "#index-speakers-nav-next",
          disabledClass: this.$style.navBtnDisabled,
        },
        autoplay: {
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        },
      },
    // isMeetingModalOpen: false,
    // meetingModalUser: null,
    }
  },

  async mounted () {
    await this.fetchSpeakers()
  },

  methods: {
    async fetchSpeakers () {
      const [res, err] = await this.$api.person.getSpeakers({
        params: {
          "filter[front]": true,
          "sort[front]": "asc",
          "paginator[limit]": 30,
        },
      })
      if (err) {
        console.error(err)
        return
      }
      this.speakers = this.$api.helpers.getList(res)
    },

    // appointMeeting (user) {
    //   this.meetingModalUser = user.user || user
    //   this.isMeetingModalOpen = true
    // },
  },
}
