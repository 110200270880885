// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aE5p2Mkl{word-wrap:break-word}.lEOzhB3k{aspect-ratio:370/559;border:2px solid transparent;border-radius:max(.56rem,min(.83vw,1rem));height:auto;margin-bottom:max(1.41rem,min(2.08vw,2.5rem));-o-object-fit:cover;object-fit:cover;transition:border .2s ease-in-out;width:100%}@supports not (aspect-ratio:1){.lEOzhB3k{height:max(19.65rem,min(29.11vw,34.94rem))}}._5ybpT0JZ{color:#b1ec52;font-size:max(1rem,min(1.46vw,1.75rem));font-weight:600;line-height:1.2;margin-bottom:max(.84rem,min(1.25vw,1.5rem))}.w7jULhL5{color:#eee;font-size:max(1rem,min(.94vw,1.13rem));line-height:1.6}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"speaker": "aE5p2Mkl",
	"img": "lEOzhB3k",
	"name": "_5ybpT0JZ",
	"position": "w7jULhL5"
};
module.exports = ___CSS_LOADER_EXPORT___;
