
import { mapGetters, mapActions } from "vuex"
import breakpoints from "@/assets/style/resources/breakpoints/module.scss"

export default {
  data () {
    return {
      swiperOptions: {
        slidesPerView: 1.2,
        spaceBetween: 20,
        breakpoints: {
          [breakpoints.sm]: { slidesPerView: 3, spaceBetween: 30 },
          [breakpoints.lg]: { slidesPerView: 3, spaceBetween: 40 },
        },
        navigation: {
          prevEl: "#index-tracks-nav-prev",
          nextEl: "#index-tracks-nav-next",
          disabledClass: this.$style.navBtnDisabled,
        },
      },
    }
  },

  computed: {
    ...mapGetters("program", { tracks_: "tracks" }),

    tracks () {
      return this.tracks_.map(t => ({
        ...t,
        localizedTitle: this.$fmt.localize(t.hall.title),
      }))
    },
  },

  async mounted () {
    await this.fetchTracks()
  },

  methods: {
    ...mapActions("program", ["fetchTracks"]),
    isHallB (hallText) {
      return hallText === "Hall B" || hallText === "Зал Б"
    },
  },
}
