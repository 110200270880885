import { render, staticRenderFns } from "./index.vue?vue&type=template&id=d2dd35d2"
import script from "./index.vue?vue&type=script&lang=ts"
export * from "./index.vue?vue&type=script&lang=ts"
import style0 from "./index.vue?vue&type=style&index=0&id=d2dd35d2&prod&module=true&lang=scss"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IndexHero: require('/app/components/index/IndexHero.vue').default,LiveStream: require('/app/components/live/stream/LiveStream.vue').default,AppContent: require('/app/components/app/AppContent.vue').default,IndexAbout: require('/app/components/index/IndexAbout.vue').default,IndexTracks: require('/app/components/index/IndexTracks.vue').default,IndexSpeakers: require('/app/components/index/IndexSpeakers.vue').default})
