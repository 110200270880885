import { render, staticRenderFns } from "./IndexSpeakers.vue?vue&type=template&id=1e148886"
import script from "./IndexSpeakers.vue?vue&type=script&lang=js"
export * from "./IndexSpeakers.vue?vue&type=script&lang=js"
import style0 from "./IndexSpeakers.vue?vue&type=style&index=0&id=1e148886&prod&module=true&lang=scss"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppIcon: require('/app/components/app/AppIcon.vue').default,IndexSpeaker: require('/app/components/index/IndexSpeaker.vue').default,AppSwiper: require('/app/components/app/AppSwiper.vue').default})
