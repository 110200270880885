
import Vue from "vue"
import { mapGetters, mapActions } from "vuex"

export default Vue.extend({
  layout: "index",

  // validate ({ store }) {
  //   return store.getters["user/isAdmin"]
  // },

  async fetch () {
    await this.fetchTracks()
  },

  computed: {
    ...mapGetters("program", ["mainTrack"]),
  },

  methods: {
    ...mapActions("program", ["fetchTracks"]),
  },
})
